
import React from 'react';
import { useEffect, useRef } from 'react';

const CaptchaImage = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = 100;
    const height = 60;
    const fontSize = 16;
    const letAmount = 5;
    const fonLetAmount = 30;
    const font = '16px Arial';

    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    const colors = ['90', '110', '130', '150', '170', '190', '210'];

    // Fill background
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, width, height);

    // Add background numbers
    for (let i = 0; i < fonLetAmount; i++) {
      const color = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.4)`;
      ctx.fillStyle = color;
      ctx.font = `${Math.random() * 4 + fontSize}px Arial`;
      ctx.fillText(
        numbers[Math.floor(Math.random() * numbers.length)],
        Math.random() * width,
        Math.random() * height
      );
    }

    // Add foreground numbers
    let code = '';
    for (let i = 0; i < letAmount; i++) {
      const color = `rgba(${colors[Math.floor(Math.random() * colors.length)]}, ${colors[Math.floor(Math.random() * colors.length)]}, ${colors[Math.floor(Math.random() * colors.length)]}, 0.8)`;
      ctx.fillStyle = color;
      ctx.font = `${Math.random() * 6 + fontSize * 2}px Arial`;
      const x = (i + 1) * fontSize + Math.random() * 5;
      const y = height / 2 + Math.random() * 10;
      const number = numbers[Math.floor(Math.random() * numbers.length)];
      code += number;
      ctx.fillText(number, x, y);
    }

    console.log('Generated CAPTCHA code:', code); // Use this for debugging

  }, []);

  return <canvas ref={canvasRef} width="100" height="60" />;
};

export default CaptchaImage;
