import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import './style.css'
import Uzhzhatlar from './views/uzhzhatlar';
import Arizalarnikribchiishmuddati from './views/arizalarni-krib-chiish-muddati';
import Submissioncollection from './views/submission-collection';
import Tegishlimalumotlar from './views/tegishli-malumotlar';
import Arizatopshirishuchunsrovnomatldirish from './views/ariza-topshirish-uchun-srovnoma-tldirish';
import Vipkhizmati from './views/vip-khizmati';
import Visametricgurukhi from './views/visametric-gurukhi';
import Shimchakhizmatlar from './views/shimcha-khizmatlar';
import Contact from './views/contact';
import Home from './views/home';
import Callcenter from './views/callcenter';
import Office from './views/office';
import Kerakliuzhzhatlar from './views/kerakli-uzhzhatlar';
import Arizatopshirishzharaeni from './views/ariza-topshirish-zharaeni';
import Onlaynsrovnomasi from './views/onlayn-srovnomasi';
import Masuldorliknibosharish from './views/masuldorlikni-bosharish';
import Ishgazhoylashishzharaeni from './views/ishga-zhoylashish-zharaeni';
import Biometrikfotosura from './views/biometrik-fotosura';
import Faq from './views/faq';
import Hengenvizasi from './views/hengen-vizasi';
import Ishtaminotibyichasrovnoma from './views/ish-taminoti-byicha-srovnoma';
import Uzbekistangermanyuz from './views/uzbekistan-germanyuz';
import Uzbekistanczechrepublic from './views/uzbekistan-czech-republic';
import Tajikistangermanyuz from './views/tajikistan-germanyuz'
import Tajikistanczechrepublic from './views/tajikistan-czech-republic'
import Uchrashuvsanasinivavatinibelgilash from './views/uchrashuv-sanasini-va-vatini-belgilash';
import Tlovshartlari from './views/tlov-shartlari';
import Khuzhzhatlartarzhimasi from './views/khuzhzhatlar-tarzhimasi';
import Biometrikmalumotlarini from './views/biometrik-malumotlarini';
import Ilovanikuzatish from './views/ilovani-kuzatish';
import Arizatopshirishdansnggiolat from './views/ariza-topshirishdan-snggi-olat';
import Primetime from './views/prime-time';
import Arizashakllari from './views/ariza-shakllari';
import Kurerlikkhizmatioralietkazibberish from './views/kurerlik-khizmati-orali-etkazib-berish';
import Fotonuskhafakschopetishkhizmatlari from './views/foto-nuskha-faks-chop-etish-khizmatlari';
import Foydaliavolalaruz from './views/foydali-avolalar-uz';
import Khabardorilishkhizmati from './views/khabardor-ilish-khizmati';
import Shengenvizasi from './views/shengen-vizasi';
import Arizaoldin from './views/ariza-oldin';
import Rasmiybayramlarkuni from './views/rasmiy-bayramlar-kuni';
import Khalarotibbiysuurta from './views/khalaro-tibbiy-suurta';
import Tekhnikakhavfsizligikrsatmalari from './views/tekhnika-khavfsizligi-krsatmalari';
import Umumiymalumot from './views/umumiy-malumot';
import Milliyviza from './views/milliy-viza';
import Talimvamalakaoshirish from './views/talim-va-malaka-oshirish';
import Contactform from './views/contact-form';
import Khodimlarbulimikonunkoidalari from './views/khodimlar-bulimi-konun-koidalari';
import NotFound from './views/not-found';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} /> {/* Default Home Page */}
        <Route component={Uzhzhatlar} exact path="/uzhzhatlar" />
        <Route component={Arizalarnikribchiishmuddati} exact path="/arizalarni-krib-chiish-muddati" />
        <Route component={Submissioncollection} exact path="/submission-collection" />
        <Route component={Tegishlimalumotlar} exact path="/tegishli-malumotlar" />
        <Route component={Arizatopshirishuchunsrovnomatldirish} exact path="/ariza-topshirish-uchun-srovnoma-tldirish" />
        <Route component={Vipkhizmati} exact path="/vip-khizmati" />
        <Route component={Visametricgurukhi} exact path="/visametric-gurukhi" />
        <Route component={Shimchakhizmatlar} exact path="/shimcha-khizmatlar" />
        <Route component={Contact} exact path="/contact" />
        <Route component={Callcenter} exact path="/callcenter" />
        <Route component={Office} exact path="/office" />
        <Route component={Kerakliuzhzhatlar} exact path="/kerakli-uzhzhatlar" />
        <Route component={Arizatopshirishzharaeni} exact path="/ariza-topshirish-zharaeni" />
        <Route component={Onlaynsrovnomasi} exact path="/onlayn-srovnomasi" />
        <Route component={Masuldorliknibosharish} exact path="/masuldorlikni-bosharish" />
        <Route component={Ishgazhoylashishzharaeni} exact path="/ishga-zhoylashish-zharaeni" />
        <Route component={Biometrikfotosura} exact path="/biometrik-fotosura" />
        <Route component={Faq} exact path="/faq" />
        <Route component={Hengenvizasi} exact path="/hengen-vizasi" />
        <Route component={Ishtaminotibyichasrovnoma} exact path="/ish-taminoti-byicha-srovnoma" />
        <Route component={Uzbekistangermanyuz} exact path="/uzbekistan-germanyuz" />
        <Route component={Uzbekistanczechrepublic} exact path="/uzbekistan-czech-republic" />
        <Route component={Tajikistangermanyuz} exact path="/tajikistan-germanyuz" />
        <Route component={Tajikistanczechrepublic} exact path="/tajikistan-czech-republic" />
        <Route component={Uchrashuvsanasinivavatinibelgilash} exact path="/uchrashuv-sanasini-va-vatini-belgilash" />
        <Route component={Tlovshartlari} exact path="/tlov-shartlari" />
        <Route component={Khuzhzhatlartarzhimasi} exact path="/khuzhzhatlar-tarzhimasi" />
        <Route component={Biometrikmalumotlarini} exact path="/biometrik-malumotlarini" />
        <Route component={Ilovanikuzatish} exact path="/ilovani-kuzatish" />
        <Route component={Arizatopshirishdansnggiolat} exact path="/ariza-topshirishdan-snggi-olat" />
        <Route component={Primetime} exact path="/prime-time" />
        <Route component={Arizashakllari} exact path="/ariza-shakllari" />
        <Route component={Kurerlikkhizmatioralietkazibberish} exact path="/kurerlik-khizmati-orali-etkazib-berish" />
        <Route component={Fotonuskhafakschopetishkhizmatlari} exact path="/foto-nuskha-faks-chop-etish-khizmatlari" />
        <Route component={Foydaliavolalaruz} exact path="/foydali-avolalar-uz" />
        <Route component={Khabardorilishkhizmati} exact path="/khabardor-ilish-khizmati" />
        <Route component={Shengenvizasi} exact path="/shengen-vizasi" />
        <Route component={Arizaoldin} exact path="/ariza-oldin" />
        <Route component={Rasmiybayramlarkuni} exact path="/rasmiy-bayramlar-kuni" />
        <Route component={Khalarotibbiysuurta} exact path="/khalaro-tibbiy-suurta" />
        <Route component={Tekhnikakhavfsizligikrsatmalari} exact path="/tekhnika-khavfsizligi-krsatmalari" />
        <Route component={Umumiymalumot} exact path="/umumiy-malumot" />
        <Route component={Milliyviza} exact path="/milliy-viza" />
        <Route component={Talimvamalakaoshirish} exact path="/talim-va-malaka-oshirish" />
        <Route component={Contactform} exact path="/contact-form" />
        <Route component={Khodimlarbulimikonunkoidalari} exact path="/khodimlar-bulimi-konun-koidalari" />
        <Route component={NotFound} />
        <Redirect to="/" /> {/* Redirects any undefined route to Home */}
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
